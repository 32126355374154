// transform objects

import { ROLES } from 'admin/constants';

/**
 * Group objects that have the same key value pair in one group
 * ex. { 25: [{ name: 'Hubert', age: 25 }, { name: 'Patrick', age: 25 }]}
 */
export function groupByKeyValue(arr, key) {
  return arr.reduce((grouped, item) => {
    const value = item[key];
    grouped[value] = grouped[value] || [];
    grouped[value].push(item);
    return grouped;
  }, {});
}

/**
 * Convert an array of objects to an indexed object based on a specified property.
 * ex. {25: {name: 'Hubert', age: 25}, 26: {name: 'Patrick', age: 26}}
 *
 * @param {Array} array - The array to be converted to an indexed object.
 * @param {string} key - The property of each element to be used as the index.
 * @returns {Object} - The indexed object.
 */
export function arrayToIndexedObject(array, key) {
  return array.reduce((accumulator, currentElement) => {
    // Get the value of the specified property for the current element.
    const indexValue = currentElement[key];
    accumulator[indexValue] = currentElement;
    return accumulator;
  }, {});
}

/**
 * Populates an array of IDs with corresponding objects and filters out non-existent IDs.
 *
 * @param {Array<string>} arrayOfIds - Array of IDs to populate with objects.
 * @param {Array<Object>} arrayOfObjects - Array of objects to search for matching IDs.
 * @returns {Array<Object>} - Array of objects that match the IDs in the input array.
 */
export function populateArrayOfIds(arrayOfIds = [], arrayOfObjects) {
  // arrayOfIds: ['1', '2', '3']
  // arrayOfObjects: [{id: '3', name: 'Tom'}, {id: '1', name: 'John'}]
  // arrayOfIdPopulatedWithObjects: [{id: '1', name: 'John'}, null, {id: '3', name: 'Tom'}]
  // id 2 is not found so it is filtered out
  const arrayOfIdPopulatedWithObjects = arrayOfIds.map((id) => {
    const foundObject = arrayOfObjects.find((obj) => obj.id === id);
    // if object is not found return null
    return foundObject ?? null;
  });
  // remove nulls
  const filteredPopulatedArray = arrayOfIdPopulatedWithObjects.filter(
    (obj) => obj !== null,
  );
  return filteredPopulatedArray;
}

// THIS IS MORE VERSITILE FUNCITON WHEN WE WANT TO USE EX. "NAME" AS A KEY:

/**
 * Populates an array of values with corresponding objects based on a specified key
 * and filters out non-existent values.
 *
 * @param {Array<any>} arrayOfValues - Array of values to populate with objects.
 * @param {Array<Object>} arrayOfObjects - Array of objects to search for matching values
 *  based on the specified key.
 * @param {string} key - The key to match the values in the objects.
 * @returns {Array<Object>} - Array of objects that match the values
 *  in the input array based on the specified key.
 */
export function populateArrayOfKey(arrayOfValues, arrayOfObjects, key) {
  // arrayOfValues: ['John', 'Mark', 'Tom']
  // arrayOfObjects: [{id: '3', name: 'Tom'}, {id: '1', name: 'John'}]
  // key: 'name'
  // arrayOfIdPopulatedWithObjects: [{id: '1', name: 'John'}, null, {id: '3', name: 'Tom'}]

  const arrayOfValuePopulatedWithObjects = arrayOfValues.map((value) => {
    const foundObject = arrayOfObjects.find((obj) => obj[key] === value);
    // if object is not found, return null
    return foundObject ?? null;
  });

  // remove nulls
  const filteredPopulatedArray = arrayOfValuePopulatedWithObjects.filter(
    (obj) => obj !== null,
  );

  return filteredPopulatedArray;
}

/**
 * Populates an object with the corresponding object for a given ID.
 *
 * @param {string} id - ID to populate with the matching object.
 * @param {Array<Object>} arrayOfObjects - Array of objects to search for matching IDs.
 * @returns {Object|null} - Object that matches the input ID, or null if not found.
 */
export function populateId(id, arrayOfObjects) {
  if (!id || !arrayOfObjects) {
    return null;
  }
  const foundObject = arrayOfObjects.find((obj) => obj.id === id);
  return foundObject ?? null;
}

/**
 * Splits a string with values separated by commas into an array of unique elements.
 *
 * @param {string} stringToSplit - The string to split into an array.
 * @returns {Array} - An array of unique elements.
 */
export function createArrayFromCommaString(stringToSplit) {
  // Split the string into an array, trim each element, and remove any empty elements
  const array = stringToSplit
    .split(',')
    .map((element) => element.trim())
    .filter((element) => element !== '');

  // Create a new array with only unique elements
  const arrayWithoutDuplicates = Array.from(new Set(array));

  return arrayWithoutDuplicates;
}
/**
 * Formats an array of strings into a sentence-like format.
 *
 * ex. ["Susy", "John", "Mary"] to "Susy, John, and Mary"
 *
 * @param {string[]} arr - The array of strings to be formatted.
 * @returns {string} The formatted sentence.
 */
export function formatArrayAsSentence(arr) {
  if (arr.length === 0) {
    return '';
  } else if (arr.length === 1) {
    return arr[0];
  } else {
    const formattedStr = `${arr.slice(0, -1).join(', ')} and ${
      arr[arr.length - 1]
    }`;
    return formattedStr;
  }
}

/**
 * Checks if there is any common element between two arrays.
 *
 * @param {Array} array1 - The first array to compare.
 * @param {Array} array2 - The second array to compare.
 * @returns {boolean} True if there is at least one common element, otherwise false.
 */
export function haveCommonElement(array1, array2) {
  return array1.some((element) => array2.includes(element));
}

/**
 * Checks if all elements in array1 are present in array2.
 *
 * @param {Array} array1 - The first array to check.
 * @param {Array} array2 - The second array to check against.
 * @returns {boolean} True if all elements in array1 are present in array2, otherwise false.
 */
export function containsAllElements(array1, array2) {
  return array1.every((arr1Item) => array2.includes(arr1Item));
}

/**
 * Checks if two arrays have the same set of elements.
 *
 * @param {Array} array1 - The first array to compare.
 * @param {Array} array2 - The second array to compare.
 * @returns {boolean} True if both arrays have the same set of elements, otherwise false.
 */
export function haveSameElements(array1, array2) {
  // if both arrays contain their elements they have the same elements
  return (
    containsAllElements(array1, array2) && containsAllElements(array2, array1)
  );
}

/**
 * Removes all non-digit characters from a given string.
 *
 * @param {string|undefined} string - The input string containing digits and non-digit characters.
 * @returns {string|undefined} - A new string containing only the digit characters,
 *  or undefined if the input is undefined.
 * @example
 * const inputString = "abc123def456";
 * const result = removeNonDigits(inputString);
 * // result is "123456"
 */
export function removeNonDigits(string) {
  return string?.replace(/\D/g, '');
}

export function removeWhiteSpace(string) {
  return string?.replace(/\s/g, '');
}

/**
 * Removes duplicate elements from an array.
 *
 * @param {Array} array - The input array with potential duplicate elements.
 * @returns {Array} - A new array with duplicate elements removed.
 */
export function removeDuplicates(array) {
  return [...new Set(array)];
}

/**
 * Checks if user is an agency representative
 * @param {String} role - role of user
 * @returns
 */
export function isAgentRepresentative(role) {
  return ROLES.agentRepresentative === role;
}

/**
 * This function checks if personal info should be hidden or not from Admin
 * @param {String} role - role of user
 * @param {String[]} agency - List of agencies
 * @returns
 */
export function hideContactInfo(role, agency) {
  return (
    (ROLES.companyAdmin === role || ROLES.eventManager === role) &&
    agency.length > 0
  );
}
