import React, { useState, useEffect, useRef } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Icon } from '../components';
import styles from '../styles/components/EventDropdown.module.scss';

const Dropdown = (props) => {
  const { data, selectedItem, onSelect } = props;
  const [isOpen, setIsOpen] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dropdownRef = useRef();

  useEffect(() => {
    if (selectedItem) {
      let index = data.findIndex((item) => item.title === selectedItem.title);
      setSelectedIndex(index);
    }
  }, [selectedItem]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOnSelect = (index) => {
    console.log('🚀 ~ index:', index);
    onSelect(index);
    setIsOpen(false);
  };

  return (
    <div className={styles.container} ref={dropdownRef}>
      <div
        className={
          isOpen ? styles.buttonContainerSelected : styles.buttonContainer
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            name={data[selectedIndex]?.icon}
            size={18}
            style={{
              color: '#0a0b09',
            }}
          />
          <div className={styles.buttonTitle}>{data[selectedIndex]?.title}</div>
        </div>
        {isOpen ? (
          <UpOutlined style={{ color: '#1890FF', fontSize: 20 }} />
        ) : (
          <DownOutlined style={{ color: '#dadada', fontSize: 20 }} />
        )}
      </div>
      {isOpen && (
        <div className={styles.optionContainer}>
          {data.map((item, index) => {
            return (
              <div
                className={styles.rowContainer}
                style={{
                  borderBottom:
                    index < data.length - 1 ? '2px solid #EAEAEA' : null,
                }}
                onClick={() => handleOnSelect(index)}
                key={item.key}
              >
                <Icon
                  name={item.icon}
                  size={18}
                  style={{
                    color: selectedIndex === index ? '#1890ff' : '#0a0b09',
                  }}
                />
                <div
                  className={
                    selectedIndex === index
                      ? styles.rowTitleSelected
                      : styles.rowTitle
                  }
                >
                  {item.title}
                </div>
                {/* {index < data.length - 1 && <div className={styles.divider} />} */}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
